import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILED,
  USER_SIGN_IN,
  USER_SIGN_IN_FAILED,
  USER_SIGN_OUT,
  CLEAR_LOGIN_ERROR,
  REQUEST_OTP,
  REQUEST_OTP_SUCCESS,
  REQUEST_OTP_FAILED,
  UPDATE_USER_WALLET_HISTORY
} from "../store/types";

import store from '../store/store';
import { firebase } from '../config/configureFirebase';
import 'firebase/app-check';

import { parsePhoneNumberFromString } from 'libphonenumber-js';

//import firebase2 from '@react-native-firebase/app';
//import auth2 from '@react-native-firebase/auth';

export const fetchUser = () => (dispatch) => {
  const {
    auth,
    singleUserRef
  } = firebase;

  dispatch({
    type: FETCH_USER,
    payload: null
  });
  auth.onAuthStateChanged(user => {
    if (user) {
      singleUserRef(user.uid).on("value", async snapshot => {
        if (snapshot.val()) {
          let profile = snapshot.val();
          profile.uid = user.uid;
          dispatch({
            type: FETCH_USER_SUCCESS,
            payload: profile
          });
        }else{
          let mobile = '';
          let email =  '';
          let firstName = '';
          let lastName = '';
          let profile_image = null;
          if(user.providerData.length == 0 && user.email){
            email = user.email;
          }
          if(user.providerData.length > 0 && user.phoneNumber){
            mobile = user.phoneNumber;
          }
          if (user.providerData.length > 0) {
            const provideData = user.providerData[0];
            if (provideData == 'phone') {
              mobile = provideData.phoneNumber;
            }
            if (provideData.providerId == 'facebook.com' || provideData.providerId == 'apple.com') {
              if (provideData.email) {
                email = provideData.email;
              }
              if (provideData.phoneNumber) {
                mobile = provideData.phoneNumber;
              }
              if (provideData.displayName) {
                if (provideData.displayName.split(" ").length > 0) {
                  firstName = provideData.displayName.split(" ")[0];
                  lastName = provideData.displayName.split(" ")[1];
                } else {
                  firstName = provideData.displayName;
                }
              }
              if (provideData.photoURL) {
                profile_image = provideData.photoURL;
              }
            }
          }
          const c = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
          const reference = [...Array(5)].map(_ => c[~~(Math.random()*c.length)]).join('');
          let userData = {
            createdAt: new Date().getTime(),
            firstName: firstName,
            lastName: lastName,
            mobile: mobile,
            email: email,
            usertype: 'customer',
            referralId: reference,
            approved: true,
            walletBalance: 0
          }
          if(profile_image){
            userData['profile_image'] = profile_image;
          }
          singleUserRef(user.uid).set(userData);
          userData.uid = user.uid;
          dispatch({
            type: FETCH_USER_SUCCESS,
            payload: userData
          });
        }
      });
    } else {
      dispatch({
        type: FETCH_USER_FAILED,
        payload: { code: store.getState().languagedata.defaultLanguage.auth_error, message: store.getState().languagedata.defaultLanguage.not_logged_in }
      });
    }
  });
};

export const validateReferer = async (referralId) => {
  const {
    config
  } = firebase;
  const response = await fetch(`https://${config.projectId}.web.app/validate_referrer`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      referralId: referralId
    })
  })
  const json = await response.json();
  return json;
};

export const checkUserExists = async (regData) => {
  const {
    config
  } = firebase;

  const settings = store.getState().settingsdata.settings;
  let host = window && window.location && settings.CompanyWebsite === window.location.origin? window.location.origin : `https://${config.projectId}.web.app`
  let url = `${host}/check_user_exists`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email: regData.email,
      mobile: regData.mobile
    })
  })
  const json = await response.json();
  return json;
};

export const createCustomToken = async (regData) => {
const { config } = firebase;

const settings = store.getState().settingsdata.settings;
    let host = window && window.location && settings.CompanyWebsite === window.location.origin
        ? window.location.origin
        : `https://${config.projectId}.web.app`;
    let url = `${host}/create_custom_token`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uid: regData.uid,  // Send the uid directly here
        })
    });
    const json = await response.json();
    return json;
};

export const userCreate = async (regData) => {
    const { config } = firebase;
    const settings = store.getState().settingsdata.settings;
    let host = window && window.location && settings.CompanyWebsite === window.location.origin
        ? window.location.origin
        : `https://${config.projectId}.web.app`;
    let url = `${host}/user_create`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                mobile: regData.mobile,
            }),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const json = await response.json();
        return json;
    } catch (error) {
        return { error: error.message };
    }
};

export const mainSignUp = async (regData) => {
  const {
    config,
    driverDocsRef
  } = firebase;
  let url = `https://${config.projectId}.web.app/user_signup`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ regData: regData })
  })
  const res = await response.json();
  return res;
};

export const requestEmailOtp = (email) => async (dispatch) => {
  const {
    config,
    app,
  } = firebase;
  dispatch({
    type: REQUEST_OTP,
    payload: true
  }); 
  
  const settings = store.getState().settingsdata.settings;
  let host = window && window.location && settings.CompanyWebsite === window.location.origin? window.location.origin : `https://${config.projectId}.web.app`
  let url = `${host}/request_email_otp`;
  try{
    const appCheck = app.appCheck();
    appCheck.activate(
      '6Lcf2n8qAAAAAJbJ52c_WLfEIOvElOjubGmqx8uY',
      // Optional argument. If true, the SDK automatically refreshes App Check
      // tokens as needed.
      true);
    const appCheckToken = await appCheck.getToken();

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Token': appCheckToken.token,
      },
      body: JSON.stringify({ email: email })
    });
    const result = await response.json();
    if(result.success){
      dispatch({
        type: REQUEST_OTP_SUCCESS,
        payload: true
      });
    }else{
      dispatch({
        type: REQUEST_OTP_FAILED,
        payload: result.error
      });
    }
  }catch(error){
    console.log(error);
    dispatch({
      type: REQUEST_OTP_FAILED,
      payload: error
    });
  }
}

export const verifyEmailOtp = (email, otp) => async (dispatch) => {
  const {
    auth,
    config
  } = firebase;
  const body = {
    email: email,
    otp: otp
  };
  try{
    const settings = store.getState().settingsdata.settings;
    let host = window && window.location && settings.CompanyWebsite === window.location.origin? window.location.origin : `https://${config.projectId}.web.app`
    let url = `${host}/verify_email_otp`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    })
    const result = await response.json();
    if(result.token){
      auth.signInWithCustomToken(result.token)
        .then((user) => {
          //OnAuthStateChange takes care of Navigation
        })
        .catch((error) => {
          dispatch({
            type: USER_SIGN_IN_FAILED,
            payload: error
          });
        });
    }else{
      dispatch({
        type: USER_SIGN_IN_FAILED,
        payload: result.error
      });
    }
  }catch(error){
    console.log(error);
    dispatch({
      type: USER_SIGN_IN_FAILED,
      payload: error
    });
  }
}

export const updateAuthEmail = async ( email, otp) => {
  const {
    auth,
    config
  } = firebase;

  const uid = auth.currentUser.uid;
  const body = {
    uid: uid,
    email: email,
    otp: otp
  };

  const settings = store.getState().settingsdata.settings;
  let host = window && window.location && settings.CompanyWebsite === window.location.origin ? window.location.origin : `https://${config.projectId}.web.app`
  let url = `${host}/update_auth_email`;
  try{
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    })
    const result = await response.json();
    if(result.success){
      return {success: true}
    }else{
      return {success: false, error: result.error}
    }
  }catch(error){
    return {success: false, error: error}
  }
}

export const requestPhoneOtp = (phoneNumber) => async (dispatch) => {
  console.log("Starting OTP request for phone:", phoneNumber);

  const { config, app } = firebase;
  dispatch({
    type: REQUEST_OTP,
    payload: true,
  });

  const settings = store.getState().settingsdata.settings;
  let host =
    window &&
    window.location &&
    settings.CompanyWebsite === window.location.origin
      ? window.location.origin
      : `https://${config.projectId}.web.app`;

  let url = `${host}/request_phone_otp`;
  console.log("API URL:", url);

  try {
    console.log("Initializing App Check...");
    const appCheck = app.appCheck();
    if (!window.__APP_CHECK_ACTIVATED__) {
      appCheck.activate(
        "6Lcf2n8qAAAAAJbJ52c_WLfEIOvElOjubGmqx8uY",
        true // Automatically refresh tokens
      );
      window.__APP_CHECK_ACTIVATED__ = true;
    }
    const appCheckToken = await appCheck.getToken();
    console.log("App Check Token:", appCheckToken.token);

    console.log("Parsing phone number...");
    const parsedPhone = parsePhoneNumberFromString(phoneNumber);
    if (parsedPhone) {
      // Format the phone number to include the '+' and '-'
      const formattedPhoneNumber = `+${parsedPhone.countryCallingCode}-${parsedPhone.nationalNumber}`;
      console.log("Country Calling Code:", parsedPhone.countryCallingCode);
      console.log("National Number:", parsedPhone.nationalNumber);
      console.log("Formatted Phone Number with + and -:", formattedPhoneNumber);

      console.log("Sending OTP request...");
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Token": appCheckToken.token,
        },
        body: JSON.stringify({ formattedPhoneNumber: formattedPhoneNumber }),
      });

      const result = await response.json();
      console.log("OTP request response:", result);

      if (result.success) {
        console.log("OTP request successful.");
        dispatch({
          type: REQUEST_OTP_SUCCESS,
          payload: true,
        });
      } else {
        console.log("OTP request failed with error:", result.error);
        dispatch({
          type: REQUEST_OTP_FAILED,
          payload: result.error,
        });
      }
    } else {
      console.error("Invalid phone number format");
      dispatch({
        type: REQUEST_OTP_FAILED,
        payload: "Invalid phone number format",
      });
    }
  } catch (error) {
    console.error("Error during OTP request:", error);
    dispatch({
      type: REQUEST_OTP_FAILED,
      payload: error,
    });
  }
};

export const modifyPhone = (phoneNumber, otp) => async (dispatch) => {
    console.log("=== Modify Phone Request Started ===");
    console.log("Phone Number:", phoneNumber);
    console.log("OTP:", otp);
    const {
        auth,
        config,
        app
    } = firebase;

    const settings = store.getState().settingsdata.settings;
    let host = window && window.location && settings.CompanyWebsite === window.location.origin
        ? window.location.origin
        : `https://${config.projectId}.web.app`;
    let url = `${host}/modify_phone`;
    console.log('url:', url);

    try {
        const currentUser = auth().currentUser;
        if (!currentUser) {
            console.error("No authenticated user found.");
            return { success: false, error: "User not logged in" };
        }

        const userAccessToken = await currentUser.getIdToken(true);
        console.log("User Access Token:", userAccessToken);
        const appCheck = app.appCheck();
        if (!window.__APP_CHECK_ACTIVATED__) {
            console.log("Activating Firebase App Check...");
            appCheck.activate('6Lcf2n8qAAAAAJbJ52c_WLfEIOvElOjubGmqx8uY', true);
            window.__APP_CHECK_ACTIVATED__ = true;
        }
        const appCheckToken = await appCheck.getToken();
        console.log("App Check Token:", appCheckToken.token);
        const parsedPhone = parsePhoneNumberFromString(phoneNumber);
        if (!parsedPhone) {
            console.error("Error parsing phone number");
            return { success: false, error: "Invalid phone number format" };
        }

        const formattedPhoneNumber = `+${parsedPhone.countryCallingCode}-${parsedPhone.nationalNumber}`;
        console.log("Country Calling Code:", parsedPhone.countryCallingCode);
        console.log("National Number:", parsedPhone.nationalNumber);
        console.log("Formatted Phone Number with + and -:", formattedPhoneNumber);

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Token': appCheckToken.token,
            },
            body: JSON.stringify({
                formattedPhoneNumber: formattedPhoneNumber,
                verificationCode: otp,
                userAccessToken : userAccessToken,
            })
        });

        console.log("Received response from API.");
        const result = await response.json();
        console.log("Response Data:", result);

        if (result.success) {
            console.log("Phone modification successful.");
            return { success: true };
        } else {
            console.error("Modify phone failed:", result.error);
            return { success: false, error: result.error };
        }

    } catch (error) {
        console.error("Modify phone request error:", error);
        return { success: false, error: "Request failed" };
    }
};

export const verifyPhoneOtp = (countryCode, phoneNumber, otp) => async (dispatch) => {
  const {
      auth,
      config,
      app,
  } = firebase;
  dispatch({
      type: REQUEST_OTP,
      payload: true,
  });
  console.log('Country Calling Code:', countryCode);
  console.log('phoneNumber:', phoneNumber)
  console.log('otp', otp);

  const settings = store.getState().settingsdata.settings;
  let host =
      window && window.location && settings.CompanyWebsite === window.location.origin
          ? window.location.origin
          : `https://${config.projectId}.web.app`;
  let url = `${host}/verify_phone_otp`;
  console.log('url:', url);

  try {
      const appCheck = app.appCheck();
      if (!window.__APP_CHECK_ACTIVATED__) {
        appCheck.activate(
        '6Lcf2n8qAAAAAJbJ52c_WLfEIOvElOjubGmqx8uY',
        true
      );
        window.__APP_CHECK_ACTIVATED__ = true;
      }
      const appCheckToken = await appCheck.getToken();
      const formattedPhoneNumber = `+${countryCode}-${phoneNumber}`;
      console.log("Formatted Phone Number with + and -:", formattedPhoneNumber);

      if (!formattedPhoneNumber) {
          dispatch({
              type: REQUEST_OTP_FAILED,
              payload: "Invalid phone number format",
          });
          console.error("Error: Invalid phone number format");
          return;
      }

      const response = await fetch(url, {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
              "X-Token": appCheckToken.token,
          },
          body: JSON.stringify({
              formattedPhoneNumber,
              verificationCode: otp,
          }),
      });
      const result = await response.json();
      console.log("Response JSON:", result);

      if (result.success && result.value?.ret?.code === 0) {
          const token = result.value.accessToken?.token;
          const phoneNumber = formattedPhoneNumber.replace("-", "");

          if (phoneNumber) {
              checkUserExists({ mobile: phoneNumber })
                  .then((response) => {
                      console.log("User existence response:", response);

                      if (response.users && response.users.length > 0) {
                          const userRecord = response.users[0];
                          const uid = userRecord.uid;

                          createCustomToken({ uid: uid })
                              .then((tokenResponse) => {
                                  console.log("Custom token response1:", tokenResponse);
                                  if (tokenResponse.token) {
                                      auth.signInWithCustomToken(tokenResponse.token)
                                          .then((user) => {
                                            console.log("User signed in1 successfully:", user);
                                            //OnAuthStateChange takes care of Navigation
                                          })
                                          .catch((error) => {
                                              console.error("Error signing in with custom token:", error);
                                              dispatch({
                                                  type: USER_SIGN_IN_FAILED,
                                                  payload: error.message,
                                              });
                                          });
                                  } else {
                                      console.error("Custom token not generated:", tokenResponse.error);
                                      dispatch({
                                          type: USER_SIGN_IN_FAILED,
                                          payload: tokenResponse.error || "Custom token not generated",
                                      });
                                  }
                              })
                              .catch((error) => {
                                  console.error("Error creating custom token:", error);
                                  dispatch({
                                      type: USER_SIGN_IN_FAILED,
                                      payload: error.message,
                                  });
                              });
                      } else {
                          const payload = { mobile: phoneNumber };

                          userCreate(payload)
                              .then((response) => {
                                  console.log("User creation response2:", response);
                                  createCustomToken({ uid: response.uid })
                                      .then((tokenResponse) => {
                                          console.log("Custom token respons2e:", tokenResponse);
                                          if (tokenResponse.token) {
                                              auth.signInWithCustomToken(tokenResponse.token)
                                                  .then((user) => {
                                                      console.log("Successful sign-in2:", user);
                                                  })
                                                  .catch((error) => {
                                                      console.error("Error signing in with custom token:", error);
                                                      dispatch({
                                                          type: USER_SIGN_IN_FAILED,
                                                          payload: error.message,
                                                      });
                                                  });
                                          } else {
                                              console.error("Custom token not generated:", tokenResponse.error);
                                              dispatch({
                                                  type: USER_SIGN_IN_FAILED,
                                                  payload: tokenResponse.error || "Custom token not generated",
                                              });
                                          }
                                      })
                                      .catch((error) => {
                                          console.error("Error creating custom token:", error);
                                          dispatch({
                                              type: USER_SIGN_IN_FAILED,
                                              payload: error.message,
                                          });
                                      });
                              })
                              .catch((error) => {
                                  console.error("Error during user creation:", error);
                                  dispatch({
                                      type: USER_SIGN_IN_FAILED,
                                      payload: error.message,
                                  });
                              });
                      }
                  })
                  .catch((error) => {
                      console.error("Error during user existence check:", error);
                      dispatch({
                          type: USER_SIGN_IN_FAILED,
                          payload: error.message,
                      });
                  });
          }
      } else {
          console.error("Backend response error:", result.value?.ret?.msg || "Unknown error");
          dispatch({
              type: USER_SIGN_IN_FAILED,
              payload: result.value?.ret?.msg || "Unknown error occurred while signing in",
          });
      }
  } catch (error) {
      console.error("Error during OTP verification:", error);
      dispatch({
          type: USER_SIGN_IN_FAILED,
          payload: error.message || "Error during OTP verification",
      });
  }
};

export const facebookSignIn = (token) => (dispatch) => {

  const {
    auth,
    facebookProvider,
    facebookCredential
  } = firebase;

  dispatch({
    type: USER_SIGN_IN,
    payload: null
  });
  if (token) {
    const credential = facebookCredential(token);
    auth.signInWithCredential(credential)
      .then((user) => {
        //OnAuthStateChange takes care of Navigation
      })
      .catch(error => {
        dispatch({
          type: USER_SIGN_IN_FAILED,
          payload: error
        });
      }
      );
  } else {
    auth.signInWithPopup(facebookProvider).then(function (result) {
      var token = result.credential.accessToken;
      const credential = facebookCredential(token);
      auth.signInWithCredential(credential)
        .then((user) => {
          //OnAuthStateChange takes care of Navigation
        })
        .catch(error => {
          dispatch({
            type: USER_SIGN_IN_FAILED,
            payload: error
          });
        }
        );
    }).catch(function (error) {
      dispatch({
        type: USER_SIGN_IN_FAILED,
        payload: error
      });
    });
  }
};

export const appleSignIn = (credentialData) => (dispatch) => {

  const {
    auth,
    appleProvider
  } = firebase;

  dispatch({
    type: USER_SIGN_IN,
    payload: null
  });
  if (credentialData) {
    const credential = appleProvider.credential(credentialData);
    auth.signInWithCredential(credential)
      .then((user) => {
        //OnAuthStateChange takes care of Navigation
      })
      .catch((error) => {
        dispatch({
          type: USER_SIGN_IN_FAILED,
          payload: error
        });
      });
  } else {
    auth.signInWithPopup(appleProvider).then(function (result) {
      auth.signInWithCredential(result.credential)
        .then((user) => {
        //OnAuthStateChange takes care of Navigation
        })
        .catch(error => {
          dispatch({
            type: USER_SIGN_IN_FAILED,
            payload: error
          });
        }
        );
    }).catch(function (error) {
      dispatch({
        type: USER_SIGN_IN_FAILED,
        payload: error
      });
    });
  }
};

export const signOut = () => (dispatch) => {

  const {
    auth,
    singleUserRef,
    walletHistoryRef,
    userNotificationsRef
  } = firebase;

  const uid = auth.currentUser.uid;

  singleUserRef(uid).off();
  walletHistoryRef(uid).off();
  userNotificationsRef(uid).off();

  singleUserRef(uid).once('value', snapshot => {
      if(snapshot.val()){
        const profile = snapshot.val();
        if (profile && profile.usertype === 'driver') {
          singleUserRef(uid).update({driverActiveStatus:false});
        }
        setTimeout(()=>{
          auth
          .signOut()
          .then(() => {
            dispatch({
              type: USER_SIGN_OUT,
              payload: null
            });
          })
          .catch(error => {
      
          });
        },2000)
      }
  });
};

export const updateProfile = (updateData) => async (dispatch) => {
  
  const {
    auth,
    singleUserRef,
    driverICRef,
    driverICRefBack,
    driverSelfieRef,
  } = firebase;

  const uid = auth.currentUser.uid;

  if (updateData.icImage) {
    await driverICRef(uid).put(updateData.icImage);
    updateData.icImage = await driverICRef(uid).getDownloadURL();
  }
  if (updateData.icImageBack) {
    await driverICRefBack(uid).put(updateData.icImageBack);
    updateData.icImageBack = await driverICRefBack(uid).getDownloadURL();
  }
  if (updateData.selfieImage) {
    await driverSelfieRef(uid).put(updateData.selfieImage);
    const selfieImageUrl = await driverSelfieRef(uid).getDownloadURL();
    updateData.selfieImage = selfieImageUrl;
    updateData.profile_image = selfieImageUrl;
  }

  singleUserRef(uid).update(updateData);
};

export const updateProfileImage = (imageBlob) => {

  const {
    auth,
    singleUserRef,
    profileImageRef,
  } = firebase;

  const uid = auth.currentUser.uid;

  profileImageRef(uid).put(imageBlob).then(() => {
    imageBlob.close()
    return profileImageRef(uid).getDownloadURL()
  }).then((url) => {
    singleUserRef(uid).update({
      profile_image: url
    });
  })
};

export const updateWebProfileImage = async (imageBlob) => {

  const {
    auth,
    singleUserRef,
    profileImageRef
  } = firebase;

  const uid = auth.currentUser.uid;

  await profileImageRef(uid).put(imageBlob);
  let image = await profileImageRef(uid).getDownloadURL();
  singleUserRef(uid).update({profile_image: image});

};

export const updatePushToken = (token, platform)  => {

  const {
    auth,
    singleUserRef,
  } = firebase;

  const uid = auth.currentUser.uid;

  singleUserRef(uid).update({
    pushToken: token,
    userPlatform: platform
  });
};

export const clearLoginError = () => (dispatch) => {
  dispatch({
    type: CLEAR_LOGIN_ERROR,
    payload: null
  });
};

export const fetchWalletHistory = () => (dispatch) => {
  const {
    auth,
    walletHistoryRef
  } = firebase;

  const uid = auth.currentUser.uid;

  walletHistoryRef(uid).on('value', snapshot => {
    const data = snapshot.val(); 
    if(data){
      const arr = Object.keys(data).map(i => {
        data[i].id = i
        return data[i]
      });
      dispatch({
        type: UPDATE_USER_WALLET_HISTORY,
        payload: arr
      });
    }
  });
};